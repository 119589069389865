label {
  opacity: 1 !important;
}

html,
body {
  height: 100%;
}

html {
  overflow-x: hidden;
}

.bp4-callout.bp4-callout-icon {
  padding-left: 3.5rem;
}

/* width */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  background: none;
  border-radius: var(--s8);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--violet40);
  border-radius: var(--s8);
}

:root {
  --synq-panel-width: 360px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: var(--violet60);
}

/* console log highlight styles */
div[class*='highlight'] {
  border-radius: 4px;
  padding: 8px;
  margin-right: 8px;
  animation: highlightFadeIn 300ms;
}

@keyframes highlightFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.highlight-error > code {
  color: var(--red70);
}

.highlight-error {
  background-color: var(--red5);
}

.highlight-warn > code {
  color: var(--orange70);
}

.highlight-warn {
  background-color: var(--orange5);
}

.highlight-skip > code {
  color: var(--yellow70);
}

.highlight-skip {
  background-color: var(--yellow5);
}

.driver-popover * {
  font-family: var(--inter);
}

.driver-popover.paradime-driver-theme {
  background-color: var(--violet50);
  color: var(--black);
  max-width: 360px;
}

.driver-popover.paradime-driver-theme .driver-popover-title {
  font-size: var(--font_size_body_bold);
}

.driver-popover.paradime-driver-theme .driver-popover-description {
  font-size: var(--font_size_body_small);
}

.driver-popover.paradime-driver-theme .driver-popover-title,
.driver-popover.paradime-driver-theme .driver-popover-description,
.driver-popover.paradime-driver-theme .driver-popover-progress-text {
  color: var(--white);
}

.driver-popover.driver-popover.paradime-driver-theme code {
  font-family: 'Courier New', Courier, monospace;
  background-color: var(--violet5);
  color: var(--grey100);
  padding: 2px 4px;
  border-radius: 4px;
}

.driver-popover.paradime-driver-theme a {
  text-decoration: underline;
  color: var(--white);
}

.driver-popover.paradime-driver-theme button {
  flex: 1;
  text-align: center;
  background-color: var(--violet5);
  color: var(--violet70);
  text-shadow: none;
  font-size: var(--font_size_button);
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
}

.driver-popover.paradime-driver-theme button:hover {
  background-color: var(--violet10);
  color: var(--violet100);
}

.driver-popover.paradime-driver-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 4px;
}

.driver-popover.paradime-driver-theme .driver-popover-close-btn {
  color: var(--white);
}

.driver-popover.paradime-driver-theme .driver-popover-close-btn:hover {
  color: var(--violet100);
}

.driver-popover.paradime-driver-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: var(--violet50);
}

.driver-popover.paradime-driver-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: var(--violet50);
}

.driver-popover.paradime-driver-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: var(--violet50);
}

.driver-popover.paradime-driver-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: var(--violet50);
}

.vis-foreground .vis-group {
  height: 38px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey10) !important;
}

.vis-labelset .vis-label .vis-inner {
  font-family: var(--inter);
  font-size: var(--font_size_caption_small);
}

.vis-labelset .vis-label {
  height: 38px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey10) !important;
}

.vis-item {
  top: unset !important;
  background-color: var(--violet10) !important;
  color: var(--grey100) !important;
  border-width: 0 !important;
  border-radius: 16px !important;
}

.vis-item.vis-selected {
  border-color: unset !important;
  background-color: var(--violet70) !important;
  color: var(--violet0) !important;
}

.vis-item.vis-range .vis-item-content {
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey100);
}

.vis-item.vis-range.vis-selected .vis-item-content {
  color: var(--violet0);
}

.vis-panel.vis-left {
  border-left: 0 solid var(--grey10) !important;
  border-top: 1px solid var(--grey10) !important;
  border-bottom: 0 solid var(--grey10) !important;
}

.vis-panel.vis-top {
  border-left: 1px solid var(--grey10) !important;
}

.vis-panel.vis-center {
  border: 1px solid var(--grey10) !important;
}

.vis-time-axis.vis-foreground {
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey100);
}

.vis-timeline {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  background-color: var(--white);
}

.vis-tooltip {
  background-color: var(--grey60) !important;
  color: var(--grey0) !important;
  border-radius: 4px !important;
  border: 0 solid !important;
  font-size: var(--font_size_caption) !important;
  font-family: var(--inter) !important;
}

.catalog-refresh-button > span > svg[data-icon="refresh"] {
  animation: none;
}

/* vizzly styles */
button.vizzly_modal-close {
  top: 16px;
  right: 16px;
}

button.vizzly_modal-close > svg {
  stroke: var(--grey100);
}

div.vizzly_empty-cell {
  border: 1px solid var(--grey10);
}

div.vizzly_modal-header {
  border-radius: 8px;
  padding: 16px;
}

div.vizzly_modal-content {
  padding: 32px 24px;
}

div.vizzly_modal-content input[type="checkbox"] {
  border-color: var(--violet30);
}

div.vizzly_modal-content input[type="checkbox"]:hover {
  border-color: var(--violet70);
}

div.vizzly_modal-content[data-component="library-content"] button:hover {
  box-shadow: 0 0 0 1px var(--grey30);
}

div.vizzly_modal-content[data-component="library-content"] button[aria-selected="true"] {
  box-shadow: 0 0 0 2px var(--grey70);
}

div.vizzly_modal-content input[type="checkbox"]:checked {
  background-color: var(--violet70);
  border-color: var(--violet70);
}

div.vizzly_modal-footer {
  padding: 16px;
}

div.vizzly-popover {
  background-color: var(--white);
  box-shadow: var(--greyShadow2dp);
  border-radius: 4px;
  width: 200px !important;
}

div.vizzly-popover li:hover {
  background-color: var(--grey5);
}

div.vizzly-data-table th {
  height: 50px;
}

div.vizzly-data-table tbody > tr {
  height: 40px;
}

div.vizzly-data-table::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

div.vizzly-data-table::-webkit-scrollbar-thumb {
  background: var(--violet40);
  border-radius: var(--s4);
}

div.vizzly-data-table::-webkit-scrollbar-thumb:hover {
  background: var(--violet60);
}

/* knock styles */
.rnf-notification-icon-button {
  color: var(--icon);
}
